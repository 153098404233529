<template>
    <v-container>
        <base-material-card icon="mdi-basket" title="Devolución de cambio físico del vendedor" class="px-5 py-3">
            <v-row>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="6">
                            <custom-select url="/vendedores/all"
                            text="razon_social"
                            label="Vendedor"
                            :disabled="this.$route.params.id?true:false"
                            @change="(value)=>{form.vendedor_id = value;loadInventarioDevolucion()}"
                            :valor="form.vendedor_id"
                            :error-messages="errors.vendedor_id"
                            ></custom-select>
                        </v-col>
                    </v-row>
                    <custom-error :errors="errors.detalles"></custom-error>
                    <v-data-table
                            :headers="headers"
                            :items="form.detalles"
                            item-key="name"
                            class="elevation-1"
                        >
                        <template v-slot:item.precio="{item}">
                            {{formatoMoneda(itemPrecio(item))}}
                        </template>
                        <template v-slot:item.importe="{item}">
                            {{formatoMoneda(itemPrecio(item) * item.cantidad)}}
                        </template>
                        <template v-slot:item.actions="{item}">
                            <v-btn v-if="!$route.params.id" style="margin-right:5px !important;" fab icon x-small @click="removeProduct(item)">
                                <v-icon>fa fa-trash-alt</v-icon>
                            </v-btn>
                        </template>
                        <template v-slot:body.append>
                            <tr>
                                <td colspan="2"></td>
                                <td>Subtotal</td>
                                <td class="font-weight-bold">{{formatoMoneda(subtotal)}}</td>
                            </tr>
                            <tr v-for="impuesto in impuestos" :key="impuesto.nombre">
                                <td colspan="2"></td>
                                <td>{{impuesto.nombre}}</td>
                                <td class="font-weight-bold">{{formatoMoneda(impuesto.importe)}}</td>
                            </tr>
                            <tr>
                                <td colspan="2"></td>
                                <td>Total</td>
                                <td class="font-weight-bold">{{formatoMoneda(total)}}</td>
                            </tr>
                        </template>
                        </v-data-table>
                </v-col>
                <v-col cols="12">
                    <v-btn v-if="!this.$route.params.id" color="primary" @click="save()" :loading="loading">Guardar</v-btn>
                    <v-btn @click="$router.push({name: 'AlmacenesDescarga'})" :loading="loading">Cancelar</v-btn>
                </v-col>
            </v-row>
        </base-material-card>
    </v-container>
</template>
<script>
    import CustomSelect from '@views/dashboard/component/CustomSelect.vue'
    import CustomError from '@views/dashboard/component/CustomError.vue'

    const MODEL = {
        vendedor_id: '',
        almacenes_tipo_id: 2,
        detalles: []
    }
    export default {
        components: {
            CustomSelect,
            CustomError
        },
        data: () => ({
            form: {
                vendedor_id: '',
                almacenes_tipo_id: 2,
                detalles: []
            },
            formProducto:{
                producto_id:'',
                producto:'',
                cantidad:''
            },
            errors: {},
            loading: false,
            headers:[
                { text: 'Cantidad', value: 'cantidad', sortable: false, align:'start' },
                { text: 'Código', value: 'producto.codigo', sortable: false, align:'start' },
                { text: 'Producto', value: 'producto.nombre', sortable: false, align:'start' },
                { text: 'Precio', value: 'precio', sortable: false, align:'start' },
                { text: 'Importe', value: 'importe', sortable: false, align:'start' },
            ]
        }),
        mounted() {
            if (this.$route.params.id) {
                this.load()
            }
        },
        computed: {
            // a computed getter
            subtotal: function () {
                if(this.form.detalles && this.form.detalles.length){
                    let subtotal = this.form.detalles.reduce((acc, item) => { return acc + this.itemImporte(item); }, 0)
                    return parseFloat(parseFloat(subtotal).toFixed(2));
                }
                return 0;
            },
            impuestos: function () {
                if(this.form.detalles && this.form.detalles.length){
                    let impuestosGroup = [];
                    const impuestos = this.form.detalles
                    // Agregando datos del producto en impuestos
                    .map((item)=>item.producto.impuestos.map((impuesto)=>{
                        const importe = this.itemImporte(item) * (impuesto.porcentaje / 100);
                        impuesto['producto'] = item.producto.nombre;
                        impuesto['cantidad'] = item.cantidad;
                        impuesto['producto_importe'] = this.itemImporte(item);
                        impuesto['importe'] = parseFloat(parseFloat(importe).toFixed(2));
                        return impuesto;
                    }))
                    // Convirtiendo datos de 2 dimensiones a 1
                    .flat()
                    .map((impuesto)=>{
                        let existeImpuesto = impuestosGroup.find((group)=>{
                            return group && group.nombre == impuesto.nombre;
                        })
                        if(existeImpuesto){
                            console.log('impuesto existe')
                            existeImpuesto['importe'] += impuesto['importe'];
                        }else{
                            impuestosGroup.push(impuesto);
                        }
                    })
                    return impuestosGroup;
                }
                return 0;
            },
            total: function () {
                const impuestos = (this.impuestos && this.impuestos.length)?this.impuestos.reduce((acc, item) => { return acc + item.importe; }, 0):0;
                return this.subtotal + impuestos;
            },
        },
        methods: {
            groupBy(array,key){
                return array.reduce(function (r, a) {
                    r[a[key]] = r[a[key]] || [];
                    r[a[key]].push(a);
                    return r;
                }, Object.create(null));
            },
            itemPrecio(item){
                if(this.$route.params.id){
                    return item.precio;
                }
                return item.producto.precios[0].precio;
            },
            itemImporte(item){
                if(this.$route.params.id){
                    return item.importe;
                }
                return this.itemPrecio(item) * item.cantidad;
            },
            loadInventarioDevolucion(){
                this.form.detalles = [];
                const {vendedor_id, almacenes_tipo_id} = this.form;
                this.$http.get(`/almacenes/vendedores/para_descarga`,{
                    params:{
                        vendedor_id,
                        almacenes_tipo_id
                    }
                }).then(response => {
                    this.form.detalles = response.data
                })
            },
            load() {
                this.$http.get(`/almacenes/descargas/${this.$route.params.id}`).then(response => {
                    this.form = response.data
                })
            },
            save() {
                this.errors = {}
                this.loading = true
                if (this.$route.params.id) {
                    this.update()
                    return
                }
                this.create()
            },
            create() {
                this.$http.post('/almacenes/descargas', this.form).then(response => {
                    this.$router.push({ name: 'AlmacenesDescarga' })
                }).catch(error => {
                    switch (error.response.status) {
                        case 422:
                            this.errors = this.errors = error.response.data.errors
                            break;

                        default:
                            break;
                    }
                }).finally(() => {
                    this.loading = false
                })
            },
        }
    }
</script>

<template>
    <span class="error--text" v-if="errors">
        {{Array.isArray(errors)?errors.join(','):errors}}
    </span>
</template>
<script>

export default ({
    name:"custom-error",
    props:{
        errors:{
            type: [String, Array],
            required: true
        },
    }
})
</script>
